import React from "react"
import HeaderSub from "../components/header_sub"
import LayoutOuter from "../components/layout_outer"
import LayoutInner from "../components/layout_inner"
import ParagraphTitle from "../components/paragraph_title"
import ParagraphText from "../components/paragraph_text"

export default function PrivacyWebsite() {
  return (
    <LayoutOuter>
      <LayoutInner>
        <div className="pb-16">
          <div className="py-16">
            <HeaderSub title="Imprint" subtitle="Tempory" />
          </div>

          <ParagraphText text="Providers pursuant to sec. 5 (1) German Telemedia Act (Telemediengesetz - TMG):" />

          <ParagraphTitle text="Address" />

          <ParagraphText text="André Thiele" />
          <ParagraphText text="Scharnhorststr. 4B" />
          <ParagraphText text="10115 Berlin" />
          <ParagraphText text="Deutschland" />

          <ParagraphTitle text="Contact" />

          <p className="text-medium text-gray-800 dark:text-gray-200">
            <a
              data-mce-href="mailto:tempory.app@gmail.com"
              href="mailto:tempory.app@gmail.com"
              title="E-Mail"
            >
              {" "}
              Mail: tempory.app@gmail.com
            </a>
          </p>

          <ParagraphTitle text="European online dispute resolution platform:" />
          <ParagraphText text="The European commission has set up an European online dispute resolution platform (ODR platform) under the following link: http://ec.europa.eu/consumers/odr/. A consumer may access the ODR platform for purposes of any extrajudicial settlement of disputes arising from online contracts for services concluded with a business established within the EU." />
        </div>
      </LayoutInner>
    </LayoutOuter>
  )
}
